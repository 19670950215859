(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .controller('ManifestacaoController', ManifestacaoController)
        .filter('DescricaoTipoDocumento', DescricaoTipoDocumento);

    ManifestacaoController.$inject = ['ManifestacaoService', 'Falha', '$stateParams'];

    function ManifestacaoController(ManifestacaoService, Falha, $stateParams) {

        var vm = this;
        vm.parametrosContador = {
            crc: $stateParams.crc,
            classificacao: $stateParams.classificacao
        };
        vm.manifestacoes = $stateParams.manifestacoes;
        vm.novaManifestacao = $stateParams.novaManifestacao;
        vm.manifestacaoEstaInativa = manifestacaoEstaInativa;
        vm.consultar = consultar;
        vm.imprimir = imprimir;

        function consultar(){
            if(naoEhNovaManifestacaoEhManifestacoesJaConsultadas()) return;

            vm.manifestacoes = [];
            ManifestacaoService.consultaPorUsuario(
                function (result) {
                    vm.manifestacoes = result.data;
                }, Falha.callbackFalha );
        }
        consultar();

        function imprimir(idManifestacao){
            ManifestacaoService.gerarRelatorio(idManifestacao, Falha.callbackFalha);
        }

        function naoEhNovaManifestacaoEhManifestacoesJaConsultadas() {
            return !vm.novaManifestacao && vm.manifestacoes && vm.manifestacoes.length > 0;
        }

    }

    function DescricaoTipoDocumento() {
        return function(codigoTipoManifestacao) {
            if(codigoTipoManifestacao ==1){
                return "Confirmação";
            }else{
                return "Negação";
            }
        };
    }

    function manifestacaoEstaInativa(manifestacao) {
        return manifestacao.indAtivo === 'N';
    }

})();
