(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('UfService', UfService);

    UfService.$inject = ['$http'];

    function UfService ($http) {

        var urlService = 'sfz-manifestacao-contador-api/api/uf';

        var services = {
            consultarEstados: consultarEstados
        };

        function consultarEstados(callbackSucesso, callbackFalha) {
            $http({
                url: urlService,
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        return services;
    }
})();
