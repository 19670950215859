(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contador', {
            parent: 'entity',
            url: '/contador',
            data: {
                authorities: ['ROLE_MAN_MANIFESTACAO','ROLE_NFC'],
                pageTitle: 'global.title.portalcontribuinte'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contador/contador.html',
                    controller: 'ContadorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contador');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('recadastro', {
                parent: 'contador',
                url: '/recadastro',
                data: {
                    authorities: ['ROLE_MAN_MANIFESTACAO', 'ROLE_NFC'],
                    pageTitle: 'global.title.portalcontribuinte'
                },
                params: {
                    crc: '',
                    classificacao: '',
                    contador : {}
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/contador/recadastrocontador/recadastro.html',
                        controller: 'RecadastroContadorController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('contador');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {

                    });
                }]
            });
    }

})();
