(function () {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .controller('RecadastroContadorController', RecadastroContadorController);

    RecadastroContadorController.$inject = ['ContadorService', 'UfService', 'MunicipioService', 'Falha', '$uibModalInstance', '$stateParams', '$state'];

    function RecadastroContadorController(ContadorService, UfService, MunicipioService, Falha, $uibModalInstance, $stateParams, $state) {

        var vm = this;
        vm.id = $stateParams.id;
        vm.contador = $stateParams.contador;
        vm.parametrosContador = {
            crc: $stateParams.crc,
            classificacao: $stateParams.classificacao
        };
        vm.estados = [];
        vm.municipios = [];
        vm.recadastoContador = {};

        vm.clearForward = clearForward;
        vm.salvar = salvar;
        vm.consultarMunicipios = consultarMunicipios;

        function salvar() {
            validarIhConstruirTelefone();

            ContadorService.salvar(vm.recadastoContador,
                function (result) {
                    clear();
                    var parametros = {
                        'crc': vm.parametrosContador.crc,
                        'classificacao': vm.parametrosContador.classificacao
                    };
                    $state.go('novamanifestacao', parametros);
                }, Falha.callbackFalha);
        }

        function validarIhConstruirTelefone() {
            if (vm.recadastoContador.numeroTelefone.length > 9) {
                vm.recadastoContador.ddd = vm.recadastoContador.numeroTelefone.substring(0, 2);
                vm.recadastoContador.numeroTelefone = vm.recadastoContador.numeroTelefone.substring(2);
            }
        }

        function carregarFormularioContador() {
            if (!vm.contador) carregarContador();
            carregarEstados();
            montarRecadastroContador(vm.contador);
            if (naoEstaNuloNemVazio(vm.contador.enderecos)) {
                consultarMunicipios(vm.contador.enderecos[0].uf);
            }
        }

        carregarFormularioContador();

        function carregarContador() {
            ContadorService.consultaPorCrcEhClassificacao(vm.parametrosContador,
                function (result) {
                    vm.contador = result.data.contador;
                }, Falha.callbackFalha);
        }

        function carregarEstados() {
            UfService.consultarEstados(
                function (result) {
                    vm.estados = result.data;
                }, Falha.callbackFalha);
        }

        function consultarMunicipios(codUf) {
            if (naoEstaNulo(codUf)) {
                MunicipioService.consultarmunicipios(codUf,
                    function (result) {
                        vm.municipios = result.data;
                    }, Falha.callbackFalha);
            }
        }

        function montarRecadastroContador(contador) {
            var enderecoContador = montarEndereco(contador.enderecos);

            vm.recadastoContador = {
                id: contador.numeroPessoaContador,
                enderecoEletronico: obterEnderecoEletronico(contador.enderecos),
                ddd: obterDdd(contador.telefones),
                numeroTelefone: obterNumeroTelefone(contador.telefones),
                endereco: enderecoContador
            };
        }

        function obterEnderecoEletronico(enderecos) {
            if (naoEstaNuloNemVazio(enderecos)) {
                return enderecos[0].enderecoEletronico;
            }
            return '';
        }

        function montarEndereco(enderecos) {
            var endereco = {
                numero: '',
                logradouro: '',
                complemento: '',
                bairro: '',
                cep: '',
                uf: '',
                municipio: null,
                enderecoEletronico: ''
            };
            var municipio = {};

            if (naoEstaNuloNemVazio(enderecos)) {
                endereco = obterEndereco(enderecos);
                municipio = obterMunicipio(endereco);
                endereco = {
                    numero: endereco.numero,
                    logradouro: endereco.logradouro,
                    complemento: endereco.complemento,
                    bairro: endereco.bairro,
                    cep: endereco.cep,
                    uf: endereco.uf,
                    municipio: municipio
                }
            }
            return endereco;
        }

        function obterEndereco(enderecos) {
            return enderecos[0];
        }

        function obterMunicipio(endereco) {
            var municipio = endereco.municipio;
            if (estaNulo(municipio)) {
                municipio = {};
            }

            return municipio;
        }

        function clearForward() {
            clear();
            $state.go('contador');
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


        function estaNulo(objeto) {
            if (objeto == null && objeto == "") {
                return true;
            }
        }

        function naoEstaNulo(objeto) {
            return !estaNulo(objeto);
        }

        function naoEstaNuloNemVazio(list) {
            if (list != null && list.length > 0) {
                return true;
            }
        }

        function obterDdd(telefones) {
            if (naoEstaNuloNemVazio(telefones)) {
                return telefones[0].ddd;
            }
            return "";
        }

        function obterNumeroTelefone(telefones) {
            if (naoEstaNuloNemVazio(telefones)) {
                return telefones[0].numero;
            }
            return "";
        }
    }
})();
