(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('ClassificacaoContadorService', ClassificacaoContadorService);

    ClassificacaoContadorService.$inject = ['$http'];

    function ClassificacaoContadorService ($http) {

        var urlService = 'sfz-manifestacao-contador-api/api/classificacaoContador';

        var services = {
            consultar: consultar
        };

        function consultar(callbackSucesso, callbackFalha) {
            $http({
                url: urlService,
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        return services;
    }
})();
