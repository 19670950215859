(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .controller('FazendarioController', FazendarioController);

    FazendarioController.$inject = ['ManifestacaoService', 'ClassificacaoContadorService', 'Falha', '$stateParams', '$state'];

    function FazendarioController(ManifestacaoService, ClassificacaoContadorService, Falha, $stateParams, $state) {

        var vm = this;
        vm.manifestacoes = $stateParams.manifestacoes;
        vm.manifestacaoEstaInativa = manifestacaoEstaInativa;
        vm.contador = $stateParams.nomecontador;
        vm.classificacoes = [];
        vm.parametrosContador = {
            crc:'',
            classificacao:''
        };

        vm.consultar = consultar;
        vm.imprimir = imprimir;

        function consultar(){
            ManifestacaoService.consultaPorCrcEhClassificacao(vm.parametrosContador,
                function (result) {
                    $state.go('manifestacao-ativa', criarParametros(result.data));
                }, Falha.callbackFalha );
        }

        function imprimir(idManifestacao){
            ManifestacaoService.gerarRelatorio(idManifestacao, Falha.callbackFalha);
        }

        function obterClassificacoes() {
            ClassificacaoContadorService.consultar(
                function (result) {
                    vm.classificacoes = result.data;
                }, Falha.callbackFalha );
        }
        obterClassificacoes();

        function criarParametros(manifestacoes) {
            return {
                manifestacoes : manifestacoes,
                nomecontador : manifestacoes[0].contador ? manifestacoes[0].contador.nome : ''
            }
        }

        function manifestacaoEstaInativa(manifestacao) {
            return manifestacao.indAtivo === 'N';
        }
    }
})();
