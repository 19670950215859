(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manifestacao', {
            parent: 'contador',
            url: '/manifestacao',
            data: {
                authorities: ['ROLE_MAN_MANIFESTACAO', 'ROLE_NFC'],
                pageTitle: 'global.title.portalcontribuinte'
            },
            params: {
                crc: '',
                classificacao: '',
                manifestacoes: [],
                novaManifestacao : false
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/manifestacao/manifestacao.html',
                    controller: 'ManifestacaoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('manifestacao');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('novamanifestacao', {
            parent: 'manifestacao',
            url: '/novamanifestacao',
            data: {
                authorities: ['ROLE_MAN_MANIFESTACAO', 'ROLE_NFC'],
                pageTitle: 'global.title.portalcontribuinte'
            },
            params: {
                'crc': '',
                'classificacao': ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/manifestacao/novamanifestacao/novamanifestacao.html',
                    controller: 'NovaManifestacaoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('manifestacao');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
