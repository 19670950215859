(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('fazendario', {
            parent: 'entity',
            url: '/fazendario',
            data: {
                authorities: ['ROLE_CON_MANIFESTACAO'],
                pageTitle: 'global.title.portalfazendario'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/fazendario/fazendario.html',
                    controller: 'FazendarioController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fazendario');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('consulta', {
            parent: 'fazendario',
            url: '/consulta',
            data: {
                authorities: ['ROLE_CON_MANIFESTACAO'],
                pageTitle: 'global.title.portalfazendario'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/fazendario/fazendarioConsulta.html',
                    controller: 'FazendarioController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fazendario');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('manifestacao-ativa', {
            parent: 'fazendario',
            url: '/manifestacao-ativa',
            data: {
                authorities: ['ROLE_CON_MANIFESTACAO'],
                pageTitle: 'global.title.portalfazendario'
            },
            params: {
                manifestacoes : [],
                nomecontador : ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/fazendario/manifestacaoAtiva.html',
                    controller: 'FazendarioController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fazendario');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
