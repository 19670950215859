(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('ContadorService', ContadorService);

    ContadorService.$inject = ['$http'];

    function ContadorService ($http) {

        var urlService = 'sfz-manifestacao-contador-api/api/contador/';
        var urlContadorLogado = 'autenticado';
        var urlConsultar = 'consultar';
        var urlConsultarRecadastro = 'consultarRecadastro';

        var services = {
            consultaPorCrcEhClassificacao: consultaPorCrcEhClassificacao,
            consultaLogado: consultaLogado,
            salvar: salvar,
            consultarRecadastro : consultarRecadastro
        };

        function consultaPorCrcEhClassificacao(parametrosContador, callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlConsultar,
                method: 'POST',
                data: JSON.stringify(parametrosContador),
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function consultaLogado(callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlContadorLogado,
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function salvar(recadastroContador, callbackSucesso, callbackFalha) {
            $http({
                url: urlService + 'recadastro',
                method: 'PUT',
                data: JSON.stringify(recadastroContador),
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function consultarRecadastro(parametrosContador, callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlConsultarRecadastro ,
                method: 'POST',
                data: JSON.stringify(parametrosContador),
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        return services;
    }
})();
