(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .controller('FazendarioEmpresaController', FazendarioEmpresaController);

    FazendarioEmpresaController.$inject = ['FazendarioEmpresa', 'ContribuinteService', 'PaginationUtil', '$stateParams', 'Falha'];

    function FazendarioEmpresaController(FazendarioEmpresa, ContribuinteService, PaginationUtil, $stateParams, Falha) {

        var vm = this;
        vm.contribuintes = [];
        vm.tipoConsulta = $stateParams.tipoConsulta;
        vm.tiposConsulta = {
            semManifestacao:"semManifestacao",
            comManifestacao :"comManifestacao"
        };

        vm.parametrosData = {dataInicial: '', dataFinal: ''};

        vm.page = '';
        vm.totalItems = null;
        vm.itemsPerPage = '';

        vm.consultar = consultar;

        vm.imprimirEmpresasNegativadas = imprimirEmpresasNegativadas;
        vm.imprimirEmpresasSemManifestacoes = imprimirEmpresasSemManifestacoes;
        vm.resetDataFinal = resetDataFinal;
        vm.convertDates = convertDates;
        vm.validaDatas = validaDatas;
        vm.mudarPagina = mudarPagina;

        function inicializarPaginacao() {
            if(vm.tipoConsulta == vm.tiposConsulta.semManifestacao){
                vm.page = PaginationUtil.parsePage($stateParams.page);
                vm.totalItems = null;
                vm.itemsPerPage = 10;
            } else if (vm.tipoConsulta == vm.tiposConsulta.comManifestacao) {
                vm.page = PaginationUtil.parsePage($stateParams.page);
                vm.totalItems = null;
                vm.itemsPerPage = 10;
            }
        }

        inicializarPaginacao();

        function consultar() {
            if(vm.tipoConsulta == vm.tiposConsulta.comManifestacao) {
                consultarComManifestacaoNegacao();
            } else if(vm.tipoConsulta == vm.tiposConsulta.semManifestacao) {
                consultarSemManifestacao();
            }
        }

        function consultarComManifestacaoNegacao() {
            vm.convertDates();
            ContribuinteService.consultaEmpresaComManifestacaoNegacao(vm.parametrosData,
                function (result) {
                    vm.totalItems = result.data.length;
                    vm.queryCount = vm.totalItems;
                    vm.contribuintes = result.data;
                    vm.contribuintesPagina = vm.contribuintes.slice(0, vm.itemsPerPage);
                }, Falha.callbackFalha);
        }

        function consultarSemManifestacao() {
            FazendarioEmpresa.query({
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, Falha.callbackFalha);
        }

        function onSuccess(data) {
            vm.totalItems = data.totalElements;
            vm.queryCount = vm.totalItems;
            vm.contribuintes = data.content;
        }

        function imprimirEmpresasNegativadas() {
            ContribuinteService.gerarRelatorioEmpresasNegativadas(vm.parametrosData ,Falha.callbackFalha);
        }

        function imprimirEmpresasSemManifestacoes() {
            ContribuinteService.gerarRelatorioEmpresasSemManifestacoes(Falha.callbackFalha);
        }

        function resetDataFinal() {
            if (vm.parametrosData.dataInicial > vm.parametrosData.dataFinal) {
                vm.parametrosData.dataFinal = '';
            }
        }

        function convertDates(){
            var dataInicial = new Date(vm.parametrosData.dataInicial);
            vm.parametrosData.dataInicial.setDate(dataInicial.getDate());
            var dataFinal = new Date(vm.parametrosData.dataFinal);
            vm.parametrosData.dataFinal.setDate(dataFinal.getDate() + 1);
          };

        function validaDatas() {
            if (vm.parametrosData.dataFinal < vm.parametrosData.dataInicial ) {
                vm.parametrosData.dataFinal = '';
            }
        }

        function mudarPagina() {
            var begin = ((vm.page - 1) * vm.itemsPerPage);
            var end = begin + vm.itemsPerPage;
  
            vm.contribuintesPagina = vm.contribuintes.slice(begin, end);
          };
    }
})();
