(function() {
    'use strict';
    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('FazendarioEmpresa', FazendarioEmpresa);

    FazendarioEmpresa.$inject = ['$resource'];

    function FazendarioEmpresa ($resource) {
        var resourceUrl =  'sfz-manifestacao-contador-api/api/contribuinte/semManifestacaoPaginado';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'}
        });
    }
})
();
