(function() {
    'use strict';
    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('ManifestacaoService', ManifestacaoService);

    ManifestacaoService.$inject = ['$http', 'DataUtils'];

    function ManifestacaoService ($http, DataUtils) {

        var urlService = 'sfz-manifestacao-contador-api/api/manifestacao/';
        var urlConsulta = 'consultar';
        var urlConsultaPorUsuario = 'consultarPorUsuario';
        var urlNovaManifestacao = 'gerarNova';
        var relatorio = 'relatorio/';

        var services = {
            consultaPorCrcEhClassificacao: consultaPorCrcEhClassificacao,
            consultaPorUsuario: consultaPorUsuario,
            gerarManifestacao: gerarManifestacao,
            gerarRelatorio: gerarRelatorio
        };

        function consultaPorCrcEhClassificacao(parametrosContador, callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlConsulta,
                method: 'POST',
                data: JSON.stringify(parametrosContador),
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function consultaPorUsuario(callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlConsultaPorUsuario,
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function gerarManifestacao(parametrosManifestacao, callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlNovaManifestacao,
                method: 'POST',
                data: JSON.stringify(parametrosManifestacao),
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function gerarRelatorio(idManifestacao, onFailure) {
            downloadRelatorio(urlService + relatorio + idManifestacao, onFailure);
        };

        function downloadRelatorio(urlRelatorio, parametros, onError) {
            $http({
                method: 'GET',
                url: urlRelatorio,
                params: parametros,
                responseType: 'arraybuffer'
            }).then(DataUtils.downloadFileAsPdf, onError);
        }

        return services;
    }
})();
