(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('ContribuinteService', ContribuinteService);

    ContribuinteService.$inject = ['$http', 'DataUtils', '$resource'];

    function ContribuinteService ($http, DataUtils, $resource) {

        var urlService = 'sfz-manifestacao-contador-api/api/contribuinte/';
        var urlPorContador = 'porContador';
        var urlPorUsuario = 'porUsuario';
        var urlRelatorio = 'relatorioPorUsuario';
        var urlRelatorioEmpresasNegativadas = 'relatorioEmpresasNegativadas';
        var urlRelatorioEmpresasSemManifestacoes = 'relatorioEmpresasSemManifestacoes';
        var urlEmpresaComManifestacao = 'comManifestacaoNegacaoSemEvento';
        var urlEmpresaSemManifestacao = 'semManifestacao';

        var services = {
            consultaPorContador: consultaPorContador,
            consultaPorUsuario: consultaPorUsuario,
            gerarRelatorioEmpresas: gerarRelatorioEmpresas,
            gerarRelatorioEmpresasNegativadas: gerarRelatorioEmpresasNegativadas,
            gerarRelatorioEmpresasSemManifestacoes: gerarRelatorioEmpresasSemManifestacoes,
            consultaEmpresaComManifestacaoNegacao: consultaEmpresaComManifestacaoNegacao,
            consultaSemManifestacao: consultaSemManifestacao
        };

        function consultaPorContador(parametrosContador, callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlPorContador,
                method: 'POST',
                data: JSON.stringify(parametrosContador),
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function consultaPorUsuario(callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlPorUsuario,
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        // function consultaEmpresaComManifestacaoNegacao(page, size, callbackSucesso, callbackFalha) {
        //     $http({
        //         url: urlService + urlEmpresaComManifestacao + "?page=" + page + "&size=" + size,
        //         method: 'GET',
        //         headers: { 'Content-Type': 'application/json'},
        //         transformRequest: angular.identity,
        //     }).then(callbackSucesso, callbackFalha);
        // }

        function consultaEmpresaComManifestacaoNegacao(parametrosData, callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlEmpresaComManifestacao,
                method: 'POST',
                data: JSON.stringify(parametrosData),
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity,
            }).then(callbackSucesso, callbackFalha);
        }

        function consultaSemManifestacao(callbackSucesso, callbackFalha) {
            $http({
                url: urlService + urlEmpresaSemManifestacao,
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
                transformRequest: angular.identity
            }).then(callbackSucesso, callbackFalha);
        }

        function gerarRelatorioEmpresas(onFailure) {
            downloadRelatorio(urlService + urlRelatorio, onFailure);
        }

        function gerarRelatorioEmpresasNegativadas(parametros, onFailure) {
            downloadRelatorio(urlService + urlRelatorioEmpresasNegativadas, parametros, onFailure);
        }

        function gerarRelatorioEmpresasSemManifestacoes(onFailure) {
            downloadRelatorio(urlService + urlRelatorioEmpresasSemManifestacoes, onFailure);
        }

        function downloadRelatorio(url, onError) {
            $http({
                method: 'GET',
                url: url,
                responseType: 'arraybuffer'
            }).then(DataUtils.downloadFileAsPdf, onError);
        }

        function downloadRelatorio(url, parametrosData, onError) {
            $http({
                method: 'POST',
                url: url,
                data: parametrosData,
                responseType: 'arraybuffer'
            }).then(DataUtils.downloadFileAsPdf, onError);
        }

        return services;
    }
})();

