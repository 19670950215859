(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('MunicipioService', MunicipioService);

    MunicipioService.$inject = ['$http'];

    function MunicipioService ($http) {

        var urlService = 'sfz-manifestacao-contador-api/api/municipio/';

        var services = {
            consultarmunicipios: consultarmunicipios
        };

        function consultarmunicipios(codUf, callbackSucesso, callbackFalha) {
            $http.get(urlService + codUf).then(callbackSucesso, callbackFalha);
        }

        return services;
    }
})();
