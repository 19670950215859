(function () {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .controller('ContadorController', ContadorController);

    ContadorController.$inject = ['ContadorService', 'ManifestacaoService', 'ClassificacaoContadorService', 'Falha', '$state'];

    function ContadorController(ContadorService, ManifestacaoService, ClassificacaoContadorService, Falha, $state) {

        var vm = this;

        vm.classificacoes = [];
        vm.parametrosContador = {crc: '', classificacao: ''};
        vm.contador = {};

        vm.consultar = consultar;

        function consultar() {
            ContadorService.consultaPorCrcEhClassificacao(vm.parametrosContador,
                function (result) {
                    vm.contador = result.data.contador;
                    verificaSeExisteRecadastro(result.data.existeRecadastro);

                }, Falha.callbackFalha);
        }

        function obterClassificacoes() {
            ClassificacaoContadorService.consultar(
                function (result) {
                    vm.classificacoes = result.data;
                }, Falha.callbackFalha);
        }

        obterClassificacoes();

        function verificaSeExisteRecadastro(existeRecadastro) {
            if (existeRecadastro) {
                consultarManifestacoes();
            } else {
                $state.go('recadastro', criarParametros());
            }
        }

        function consultarManifestacoes() {
            ManifestacaoService.consultaPorCrcEhClassificacao(vm.parametrosContador,
                function (result) {
                    $state.go('manifestacao', criarParametros(result.data));
                },
                function (falha) {
                    $state.go('novamanifestacao');
                });
        }

        function criarParametros(manifestacoes) {
            return {
                crc: vm.parametrosContador.crc,
                classificacao: vm.parametrosContador.classificacao,
                manifestacoes: manifestacoes,
                contador: vm.contador
            };
        }
    }
})();
