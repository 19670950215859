(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .controller('NovaManifestacaoController', NovaManifestacaoController);

    NovaManifestacaoController.$inject = ['ManifestacaoService', 'ContribuinteService', 'ContadorService', 'Falha', '$stateParams', '$state', '$uibModal', '$scope'];

    function NovaManifestacaoController(ManifestacaoService, ContribuinteService, ContadorService, Falha, $stateParams, $state, $uibModal, $scope) {

        var vm = this;
        vm.tipoManifestacao = { confirmacao : 1, negacao:2 }
        vm.contribuintes = [];
        vm.parametrosContador = {crc: $stateParams.crc, classificacao: $stateParams.classificacao};

        vm.consultarContribuintes = consultarContribuintes;
        vm.addContribuinte = addContribuinte;
        vm.checkSelected = checkSelected;
        vm.confirmaNovaManifestacao = confirmaNovasManifestacoes;
        vm.modalInstance = null;

        var contribuintesSelecionados = [];
        var contribuintesNaoSelecionados = [];

        function consultarContribuintes() {
            if(parametrosContadorValidos()){
                ContribuinteService.consultaPorContador(
                    vm.parametrosContador,
                    function (result) {
                        vm.contribuintes = result.data;
                        carregarContribuintesNaoSelecionados(vm.contribuintes);
                    }, Falha.callbackFalha );

            } else {
                validaParametrosContador();
                ContribuinteService.consultaPorUsuario(
                    function (result) {
                        vm.contribuintes = result.data;
                        carregarContribuintesNaoSelecionados(vm.contribuintes);
                    }, Falha.callbackFalha );
            }
        }

        consultarContribuintes();

        function confirmaNovasManifestacoes() {
            ManifestacaoService.consultaPorCrcEhClassificacao(
                vm.parametrosContador,
                function (result) {
                    if(naoEstaNuloNemVazio(result.data)){
                        abrirModalConfirmacaoManifestacao();
                    } else {
                        novasManifestacoes();
                    }
                },
                function (result) {
                    novasManifestacoes();
                }
            );
        }

        function abrirModalConfirmacaoManifestacao () {
            vm.modalInstance = $uibModal.open({
                animation: true,
                size: 'sm',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'confirmarnovamanifestacao.html',
                scope: $scope
            });

            vm.modalInstance.result.then(function () {
                novasManifestacoes();
            }, function () {});
        }

        vm.ok = function () {
            vm.modalInstance.close();
        };

        vm.cancel = function () {
            vm.modalInstance.dismiss('cancel');
        };

        function novasManifestacoes() {
            var parametrosManifestacoes = configurarParametrosManifestacao(contribuintesNaoSelecionados, contribuintesSelecionados);

            ManifestacaoService.gerarManifestacao(parametrosManifestacoes,
                function (resposta) {
                    var parametros = {'crc' : vm.parametrosContador.crc, 'classificacao' : vm.parametrosContador.classificacao, novaManifestacao:true};
                    $state.go('manifestacao', parametros);
                },
                function (falha) {
                    $state.reload();
                    Falha.callbackFalha(falha);
                    if(falha.data.description.indexOf("recadastro") != -1){
                        var parametros = {'crc' : vm.parametrosContador.crc, 'classificacao' : vm.parametrosContador.classificacao};
                        $state.go('recadastro', parametros);
                    }
                });
        }

        function validaParametrosContador(){
            if(!parametrosContadorValidos()){
                ContadorService.consultaLogado(function (result) {
                    vm.parametrosContador = result.data;
                    return true;
                }, Falha.callbackFalha);
            }
        }

        function configurarParametrosManifestacao(contribuintesConfirmados, contribuintesNegados) {
            var parametrosManifestacoes = {
                crc: '',
                classificacao: '',
                manifestacoes: []
            };

            parametrosManifestacoes.crc = vm.parametrosContador.crc;
            parametrosManifestacoes.classificacao = vm.parametrosContador.classificacao;

            parametrosManifestacoes.manifestacoes = parametrosManifestacoes.manifestacoes
                .concat(gerarManifestacoes(contribuintesConfirmados, vm.tipoManifestacao.confirmacao));

            parametrosManifestacoes.manifestacoes = parametrosManifestacoes.manifestacoes
                .concat(gerarManifestacoes(contribuintesNegados, vm.tipoManifestacao.negacao));

            return parametrosManifestacoes;
        }

        function addContribuinte(numeroCaceal) {
            var idx = contribuintesSelecionados.indexOf(numeroCaceal);
            if (contribuintesSelecionados.indexOf(numeroCaceal) == -1) {
                contribuintesSelecionados.push(numeroCaceal);
                contribuintesNaoSelecionados = contribuintesNaoSelecionados
                                                        .filter(function(item) {
                                                            return item !== numeroCaceal;
                                                        })
            } else {
                contribuintesNaoSelecionados.push(numeroCaceal);
                contribuintesSelecionados.splice(idx,1);
            }
        }

        function checkSelected(numeroCaceal) {
            var idx = contribuintesSelecionados.indexOf(numeroCaceal);
            return idx > -1;
        }

        function carregarContribuintesNaoSelecionados(contribuintes) {
            for(var i = 0 ; i<contribuintes.length ; i++){
                contribuintesNaoSelecionados.push(contribuintes[i].numeroCaceal);
            }
        }

        function parametrosContadorValidos() {
            return vm.parametrosContador.crc != '' && vm.parametrosContador.classificacao != '';
        }

        function naoEstaNuloNemVazio(list) {
            if(list != null && list.length > 0){
                return true;
            }
        }

        function gerarManifestacoes(contribuintes, tipoManifestacao) {
            var manifestacoes = [];
            var index;

            for(index = 0; index < contribuintes.length; index++) {
                manifestacoes.push(novaManifestacao(contribuintes[index], tipoManifestacao));
            }

            return manifestacoes;
        }

        function novaManifestacao(caceal, tipoManifestacao) {
            var manifestacao = {};

            manifestacao.caceal = caceal;
            manifestacao.tipoManifestacao = tipoManifestacao;

            return manifestacao;
        }
    }

})();
