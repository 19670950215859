(function() {
    'use strict';

    angular
        .module('sfzManifestacaoContadorGatewayApp')
        .factory('Falha', Falha);

    Falha.$inject = ['AlertService'];

    function Falha (AlertService) {

        var services = {
            callbackFalha: callbackFalha
        };

        function callbackFalha(falha) {
            if(falha.data.fieldErrors){
                for(var i=0; i < falha.data.fieldErrors.length;i++ ){
                    AlertService.error(falha.data.fieldErrors[i].message);
                }
            }else if(falha.data.description) {
                AlertService.error(falha.data.description);
            } else {
                AlertService.error(falha.data.error);
            }
        }

        return services;
    }
})();
